<template>
  <div class="story">
    <div 
        class="story__image"
        :style="{ 'backgroundImage': `url(${story.url})` }"
    >
    <button class="story__delete" @click="deleteCurrentStory()">Borrar</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        story: {
            type: Object,
            default: () => {}
        }
    },

    methods: {
      async deleteCurrentStory() {
        
        try {
          await this.deleteStory({ id: this.story.id, urlImage: this.story.url })
          this.$toast.success('Se borro la story correctamente')
        } catch (error) {
          this.$toast.error(
            "Ocurrio un error al intentar borrar la story"
          );
        }

      },
      ...mapActions('webStore', ['deleteStory'])
    }
}
</script>

<style>

</style>
